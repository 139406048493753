<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Total Nilai PO</label>
        <v-money class="w-full" :value="header.nilai_total" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">DPP</label>
        <v-money class="w-full" :value="header.dpp" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPN Jenis</label>
        <vs-input class="w-full" :value="header.ppn_jenis || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPN</label>
        <vs-input class="w-full" :value="`${idr(header.ppn_jml || 0)} (${header.ppn_persen || 0}%)`" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPh Jenis</label>
        <vs-input class="w-full" :value="header.pph_nama || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">PPh</label>
        <vs-input class="w-full" :value="`${idr(header.pph_jml || 0)} (${header.pph_persen || 0}%)`" readonly/>
      </div>
    </div>

    <vs-table :data="pembayaran" stripe class="fix-table-input-background mt-base">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">Termin</vs-th>
        <vs-th class="whitespace-no-wrap">Tgl Due</vs-th>
        <vs-th class="whitespace-no-wrap">Persen Bayar</vs-th>
        <vs-th class="whitespace-no-wrap">Jumlah</vs-th>
        <vs-th class="whitespace-no-wrap">DPP</vs-th>
        <vs-th class="whitespace-no-wrap">PPN</vs-th>
        <vs-th class="whitespace-no-wrap">PPh</vs-th>
        <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td class="whitespace-no-wrap">{{ item.no_termin }}</vs-td>
          <vs-td class="whitespace-no-wrap">{{ item.tgl_due }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.persen | idr }}%</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.jml_total | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.dpp | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.ppn_jml | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.pph_jml | idr }}</vs-td>
          <vs-td class="whitespace-no-wrap text-right">{{ item.keterangan || '-' }}</vs-td>
        </vs-tr>
        <vs-tr class="text-sm font-semibold">
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td class="text-right">{{ footer.totalPersen | idr }}%</vs-td>
          <vs-td class="text-right">{{ footer.totalJumlah | idr }}</vs-td>
          <vs-td class="text-right">{{ footer.totalDpp | idr }}</vs-td>
          <vs-td class="text-right"> {{ footer.totalPpn | idr }}</vs-td>
          <vs-td class="text-right"> {{ footer.totalPph | idr }}</vs-td>
          <vs-td></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="prev" type="border" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'

export default {
  name: 'TabPembayaran',
  props: {
    initData: { default: null, type: Object }
  },
  components: {
    VMoney
  },
  computed: {
    header () {
      return this.initData.header
    },
    pembayaran () {
      return this.initData.pembayaran
    },
    footer () {
      return {
        totalPersen: _.sumBy(this.pembayaran, item => item.persen || 0),
        totalJumlah: _.sumBy(this.pembayaran, item => item.jml_total || 0),
        totalDpp: _.sumBy(this.pembayaran, item => item.dpp || 0),
        totalPpn: _.sumBy(this.pembayaran, item => item.ppn_jml || 0),
        totalPph: _.sumBy(this.pembayaran, item => item.pph_jml || 0)
      }
    }
  },
  methods: {
    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.poKontrakApproval.activeTab
      this.$store.commit('approvals/poKontrakApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
